import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=ef80adba&scoped=true"
import script from "./auth.vue?vue&type=script&lang=js"
export * from "./auth.vue?vue&type=script&lang=js"
import style0 from "./auth.vue?vue&type=style&index=0&id=ef80adba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef80adba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppPopupOverlay: require('/app/components/app/popup/Overlay.vue').default,AppPopupMessage: require('/app/components/app/popup/Message.vue').default,AppHeader: require('/app/components/app/Header.vue').default,CoreSvgIcon: require('/app/components/core/SvgIcon.vue').default})
