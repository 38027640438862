
export default {
  name: "default",
  head() {
    return {
      meta: [
        { hid: "robots", name: "robots", content: "index,follow" },
        { hid: "googlebot", name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      pageScrolled: false,
    };
  },
  computed: {
    popupBgFlag() {
      return (
        this.$store.state.overlay.flag || this.$store.state.messagePopup.flag
      );
    },
  },

  mounted() {
    window.addEventListener('scroll', () => {
      this.pageScrolled = window.scrollY > 0;
    });

    const toMatch = [
      /Android/i,
      /BlackBerry/i,
    ];
    if (toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem))) {
      this.applicationBanner = true;
    }

    const isIOS = [
      /iPhone/i,
      /iPad/i,
      /iPod/i,
    ];
    if (isIOS.some((toMatchItem) => navigator.userAgent.match(toMatchItem))) {
      this.$store.dispatch("webAppPopup/toggleOverlay", true);
    }
  }
};
