import { render, staticRenderFns } from "./single.vue?vue&type=template&id=fb8346aa&scoped=true"
import script from "./single.vue?vue&type=script&lang=js"
export * from "./single.vue?vue&type=script&lang=js"
import style0 from "./single.vue?vue&type=style&index=0&id=fb8346aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb8346aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppPopupDashboardTetherDeposit: require('/app/components/app/popup/dashboard/TetherDeposit.vue').default,AppPopupAddLirAccount: require('/app/components/app/popup/AddLirAccount.vue').default,AppPopupOverlay: require('/app/components/app/popup/Overlay.vue').default,AppPopupMessage: require('/app/components/app/popup/Message.vue').default,AppHeader: require('/app/components/app/Header.vue').default})
