

export default {
  name: "auth",
  head() {
    return {
      meta: [
        {hid: "robots", name: "robots", content: "noindex,nofollow"},
        {hid: "googlebot", name: "robots", content: "noindex,nofollow"},
      ]
    }
  },
  data() {
    return {
      pageScrolled: false,
      user: null,
    }
  },
  computed: {
    popupBgFlag() {
      return (this.$store.state.overlay.flag || this.$store.state.messagePopup.flag);
    }
  },
  beforeMount() {
    if (this.$auth.loggedIn) {
      this.$router.push('/dashboard');
    }
  },
  async fetch() {
    // await this.$store.dispatch('config/getConfig');
    /* if (this.$auth.user) {
      this.user = this.$auth.user;
    } */
  }
}
