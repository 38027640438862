

export default {
  name: "default",
  head() {
    return {
      meta: [
        { hid: "robots", name: "robots", content: "index,follow" },
        { hid: "googlebot", name: "robots", content: "index,follow" },
      ]
    }
  },
  data() {
    return {
      pageScrolled: false,
      user: null,
    }
  },
  computed: {
    popupBgFlag() {
      return (this.$store.state.overlay.flag || this.$store.state.messagePopup.flag);
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.pageScrolled = window.scrollY > 0;
    });
  }
}
