
export default {
  props: ['error'],
  layout: 'error', // you can set a custom layout for the error page
  data() {
    return {
      pageScrolled: false,
      user: null,
    }
  },
  computed: {
    popupBgFlag() {
      return (this.$store.state.overlay.flag || this.$store.state.messagePopup.flag);
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.pageScrolled = window.scrollY > 0;
    });
  }
}
