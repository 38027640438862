
import dashboardLinks from "@/data/dashboard-links.json";

export default {
  name: "dashboard",
  layout: "dashboard",
  middleware: ["auth"],
  // middleware: 'authenticated',
  head() {
    return {
      meta: [
        { hid: "robots", name: "robots", content: "noindex,nofollow" },
        { hid: "googlebot", name: "robots", content: "noindex,nofollow" },
      ],
    };
  },
  data() {
    return {
      pageScrolled: false,
      user: null,
      sideMenuLinks: dashboardLinks,
    };
  },
  computed: {
    price() {
      return {
        usdt: this.$store.state.usdtConfig.currentPrice,
        lowPrice: this.$store.state.usdtConfig.lowestPrice,
        hightPrice: this.$store.state.usdtConfig.highestPrice,
        percentageDifference: this.$store.state.usdtConfig.dailyPercentage,
      }
    },
    lirPrice() {
      return {
        lir: this.$store.state.lirConfig.currentLirPrice,
        lowLirPrice: this.$store.state.lirConfig.lowestLirPrice,
        hightLirPrice: this.$store.state.lirConfig.highestLirPrice,
        percentageDifference: this.$store.state.lirConfig.dailyPercentage,
      }
    },
    popupBgFlag() {
      return (
        this.$store.state.overlay.flag || this.$store.state.messagePopup.flag
      );
    },
    kycStatus() {
      if (this.$auth.user && !this.$auth.user.isMobileOwner) {
        return 0;
      } else if (
        this.$auth.user.ImageKYCStatus === "NONE" ||
        this.$auth.user.ImageKYCStatus === "CANCELED"
      ) {
        return 1;
      } else if (
        this.$auth.user.VideoKYCStatus === "NONE" ||
        this.$auth.user.VideoKYCStatus === "CANCELED"
      ) {
        return 3;
      } else {
        if (
          this.$auth.user.ImageKYCStatus === "PENDING" &&
          this.$auth.user.ImageKYCStatus === "PENDING"
        ) {
          return 4;
        } else {
          return 4;
        }
      }
    },
  },
  methods: {
    goToProfile() {
      this.$router.push("/dashboard/profile");
    },
    goToKyc(id) {
      let url;
      switch (id) {
        case 0:
          url = "/dashboard/kyc/user-info";
          break;
        case 1:
          url = "/dashboard/kyc/accept-rules";
          break;
        case 2:
          url = "/dashboard/kyc/upload-card";
          break;
        case 3:
          url = "/dashboard/kyc/upload-video";
          break;
        default:
          url = "/dashboard";
      }
      this.$router.push(url);
    },
  },
  async fetch() {
    // await this.$store.dispatch('config/getConfig');
    if (this.$auth.user) {
      this.user = this.$auth.user;
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.pageScrolled = window.scrollY > 0;
    });

    const toMatch = [
      /Android/i,
      /BlackBerry/i,
    ];
    if (toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem))) {
      this.applicationBanner = true;
    }

    const isIOS = [
      /iPhone/i,
      /iPad/i,
      /iPod/i,
    ];
    if (isIOS.some((toMatchItem) => navigator.userAgent.match(toMatchItem))) {
      this.$store.dispatch("webAppPopup/toggleOverlay", true);
    }
  }
};
